<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-12-31 11:37:58
 * @LastEditors: 贾峰
 * @LastEditTime: 2020-03-13 11:53:52
 -->
<template>
    <div class="mainpage">
        <div class="pagetitle">
            <span class="themetitle">业务情况:</span>
            <div class="personbtn" ref="personbtn" @touchend="business_person"><span>个人</span></div>
            <div class="teambtn" ref="teambtn" @touchend="business_team"><img src="@/assets/hx/image/exchange.png" alt=""><span>团队</span></div>
            <div class="changemon"><div class="changemontop"><button ref="last_P" class="last_P" @click.stop="to_lastmon">上一月</button><p>∣</p><button ref="next_p" class="next_p" :disabled="nextmonstatus"  @click.stop="to_nextmon">下一月</button></div>
            <div class="now_date">{{now_date}}</div></div>
        </div>
        <div class="titledetails">
            <div class="detail_left"><p>当月预收</p><p>{{lj.dy ? lj.dy : '0.00'}}</p></div>
            <div class="detail_center"></div>
            <div class="detail_left detail_right"><p>年累计</p><p>{{lj.nlj ? lj.nlj : '0.00'}}</p></div>
        </div>
        <div class="new_policy_chart">
            <div class="new_policy_chart_title"><p>新单保费</p><p>预收保费同比<span :style="'color:'+ (dyzj && dyzj.indexOf('-') != -1 ? 'lightgreen' : 'rgba(242,98,81,1)')">{{dyzj}}</span></p></div>
            <div class="new_policy_chart_chartbody" ref="trackchart">
                <ve-line  :data="chartData" :height="(chartheight + 62) + 'px'"  :loading="chartloading" :settings="chartSettings" :resizeable="false" :extend="extend" judge-width></ve-line>
            </div>
        </div>
        <div class="page_between"></div>
        <div class="r_13rate">
            <div class="r_13rate_title"><p>续期继续率</p></div>
            <div class="r_13rate_body">
                <div class="R13">
                    <p><span>R13</span><span>{{R13.jxl}}</span></p>
                    <div class="mount"><span>{{R13.yjbf + '/'+ R13.sjbf}}</span></div>
                    <div class="R13_process" :style="'width:'+ R13.jxl"></div>
                </div>
                <div class="R13">
                    <p><span>R25</span><span>{{R25.jxl}}</span></p>
                    <div class="mount"><span>{{R25.yjbf + '/'+ R25.sjbf}}</span></div>
                    <div class="R13_process" :style="'width:'+ R25.jxl"></div>
                </div>
                <div class="R13">
                    <p><span>R37</span><span>{{R37.jxl}}</span></p>
                    <div class="mount"><span>{{R37.yjbf + '/'+ R37.sjbf}}</span></div>
                    <div class="R13_process" :style="'width:'+ R37.jxl"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { businessTracking } from '@/api/hx/businesstrack'
import { Toast } from 'mand-mobile'
export default {
  data () {
    this.chartSettings = {
      labelMap: {
        'day': '日期',
        'ysbf': '预收保费'
      }
    }
    this.extend = {
      'xAxis.0.axisLabel.rotate': 45,
      'series.0.itemStyle': {
        normal:{
          color: 'rgb(252,105,90)',
          lineStyle:{
            color: 'rgb(252,105,90)'
          }
        }
      },

    }
    return {
      chartloading: true,
      chartheight: 0,
      R13: {
        sjbf: '0.00',
        yjbf: '0.00',
        jxl: '0%'
      },
      R25: {
        sjbf: '0.00',
        yjbf: '0.00',
        jxl: '0%'
      },
      R37: {
        sjbf: '0.00',
        yjbf: '0.00',
        jxl: '0%'
      },
      lj: {
        dy: '0.00',
        nlj: '0.00'
      },
      dyzj: '0.00',
      now_date: '',
      chartData: {
        columns: ['day', 'ysbf'],
        rows: []
      },
      nextmonstatus: true,
      persontype: 'personal'
    }
  },
  watch: {
    now_date (val) {
      if (Number(val.slice(0, 4)) === new Date().getFullYear() && Number(val.slice(5, 7)) === (new Date().getMonth() + 1)) { this.nextmonstatus = true } else { this.nextmonstatus = false; this.$forceUpdate() }
    }
  },
  // beforeCreate () {
  //   Toast.loading('拼命加载中...')
  // },
  created () {
    if ((new Date().getMonth() + 1) < 10) {
      this.now_date = new Date().getFullYear() + '-0' + (new Date().getMonth() + 1)
    } else { this.now_date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) }
    this.getData()
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.chartheight = this.$refs.trackchart.clientHeight
        console.log(this.chartheight)
      }, 1000)
    })
  },
  beforeUpdate () {
    this.$nextTick(() => {
      this.chartheight = this.$refs.trackchart.clientHeight
    })
  },
  methods: {
    getData () {
      let year = this.now_date.slice(0, 4)
      let month = this.now_date.slice(5, 7)
      this.R13 = {
        sjbf: '0.00',
        yjbf: '0.00',
        jxl: '0%'
      }; this.R25 = {
        sjbf: '0.00',
        yjbf: '0.00',
        jxl: '0%'
      }; this.R37 = {
        sjbf: '0.00',
        yjbf: '0.00',
        jxl: '0%'
      }; this.dyzj = ''; this.lj = {
        dy: '0.00',
        nlj: '0.00'
      }
      businessTracking({ year, month, type: this.persontype }).then(res => {
        this.R13 = res.data.data.R13 !== null ? res.data.data.R13 : this.R13
        this.R25 = res.data.data.R25 !== null ? res.data.data.R25 : this.R25
        this.R37 = res.data.data.R37 !== null ? res.data.data.R37 : this.R37
        this.dyzj = res.data.data.dyzj
        this.lj = res.data.data.lj !== null ? res.data.data.lj : this.lj
        this.chartData.rows = res.data.data.monthOutlist
        Toast.hide()
        this.chartloading = false
      })
    },
    to_lastmon () {
      Toast.loading('拼命加载中...')
      this.chartloading = true
      this.chartloading = true
      let year = Number(this.now_date.slice(0, 4))
      let mon = Number(this.now_date.slice(5, 7))
      if (mon === 1) { mon = 12; year = year - 1 } else mon = mon - 1
      mon = mon < 10 ? '0' + mon : mon
      this.now_date = year + '-' + mon + ''
      this.getData()
    },
    to_nextmon () {
      Toast.loading('拼命加载中...')
      this.chartloading = true
      let year = Number(this.now_date.slice(0, 4))
      let mon = Number(this.now_date.slice(5, 7))
      if (mon === 12) { mon = 1; year = year + 1 } else mon = mon + 1
      mon = mon < 10 ? '0' + mon : mon
      this.now_date = year + '-' + mon + ''
      this.getData()
    },
    business_person () {
      Toast.loading('拼命加载中...')
      this.chartloading = true
      this.persontype = 'personal'
      this.$refs.personbtn.className = 'personbtn'
      this.$refs.teambtn.className = 'teambtn'
      this.getData()
    },
    business_team () {
      Toast.loading('拼命加载中...')
      this.chartloading = true
      this.persontype = 'team'
      this.$refs.personbtn.className = 'personbtnactive'
      this.$refs.teambtn.className = 'teambtnactive'
      this.getData()
    }
  }
}
</script>
<style lang="stylus" scoped>
.mainpage{
    width 100vw
    height 100vh
    background-color white
}
.pagetitle{
    height 14.94%
    background: color-primary
    background: url('~@/assets/hx/image/busytrace.png') no-repeat;
    background-size: 100% 100%;
    position relative
    .themetitle{
      color white
      position absolute
      z-index 10
      left 6.59%
      font-size 36px
    }
    .personbtn{
        transition all 0.2s ease
        width 20%
        height 25%
        background-color rgb(233,39,50)
        position absolute
        z-index 10
        left 6.59%
        top 24%
        display flex
        justify-content center
        align-items center
        border-radius 5px
        span{
            font-size 36px
            font-family:PingFang SC
            color:rgba(255,255,255,1)
        }
    }
    .personbtnactive{
        transition all 0.2s ease
        width 20%
        height 20.33%
        background-color rgb(233,39,50)
        position absolute
        z-index 8
        left 6.59%
        top 24%
        display flex
        justify-content center
        align-items center
        border-radius 5px
        span{
            font-size 32px
            font-family:PingFang SC
            color:rgba(255,255,255,1)
        }
    }
    .teambtn{
        transition all 0.2s ease
        width 20%
        height 20.33%
        background-color rgba(253,103,109,1)
        position absolute
        z-index 9
        left 24.8%
        top 24%
        display flex
        justify-content flex-end
        align-items center
        border-radius 5px
        img{
            width 30px
            height 30px
            margin-right 10px
        }
        span{
            font-size 32px
            font-family:PingFang SC;
            font-weight:400;
            color:rgba(255,255,255,1);
            margin-right 20px
        }
    }
    .teambtnactive{
        transition all 0.2s ease
        width 20%
        height 25%
        background-color rgba(253,103,109,1)
        position absolute
        z-index 11
        left 24.8%
        top 24%
        display flex
        justify-content flex-end
        align-items center
        border-radius 5px
        img{
            width 40px
            height 40px
            margin-right 10px
        }
        span{
            font-size 36px
            font-family:PingFang SC;
            font-weight:400;
            color:rgba(255,255,255,1);
            margin-right 20px
        }
    }
    .changemon{
        border-radius 14px
        width 29.39%
        height 40%
        background-color rgba(253,103,109,1)
        position absolute
        right 6.59%
        top 10%
        display flex
        justify-content center
        align-items center
        flex-direction column
        .changemontop{
            display flex
            p{
             font-size 30px
             font-family:PingFang SC
             color:rgba(255,255,255,1)
             margin 0 5px
            }
            button{
             font-size 30px
             font-family:PingFang SC
             color:rgba(255,255,255,1)
             background-color:rgba(255,255,255,0)
             margin 0 5px
             border-radius 5px
            }
            .last_P:active{
                color #02A7E9
                background-color:rgba(255,255,255,0.2)
            }
            .next_p:active{
                color #02A7E9
                background-color:rgba(255,255,255,0.2)
            }
        }
        .now_date{
            font-size 34px
            font-family:PingFang SC
            color:rgba(255,255,255,1)
        }
    }
}
.titledetails{
    height 12.38%
    width 86.64%
    background-color rgba(255,255,255,0.9)
    margin 0 auto
    transform translateY(-50%)
    border-radius 14px
    -moz-box-shadow:0px 2px 12px #BABABA
    -webkit-box-shadow:0px 2px 12px #BABABA
    box-shadow:0px 2px 12px #BABABA
    display flex
    align-items center
    .detail_left{
        width 50%
        height 100%
        display flex
        flex-direction column
        justify-content center
        align-items center
        p:nth-child(1){
            font-size 36px
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(115,116,117,1);
        }
        p:nth-child(2){
            font-size 58px
            font-family:LEXUS-HeiS-Bold-U;
            color:rgba(0,0,0,1);
            margin-top 14px
        }
    }
    .detail_right{}
    .detail_center{
        width 0
        height 40%
        border 1px solid rgba(216,226,233,0.5)
    }
}
.new_policy_chart{
    height 38vh
    background-color white
    .new_policy_chart_title{
        margin-left 6.84vw
        margin-top -3vh
        height 20%
        p:nth-child(1){
            font-size 40px
            font-family:PingFang SC;
            color:rgba(50,50,50,1);
            font-weight bold
        }
        p:nth-child(2){
            font-size 32px
            font-family:PingFang SC;
            color:rgba(144,147,160,1);
            margin-top 14px
            span{
                font-size 40px
                font-family:LEXUS-HeiS-Bold-U;
                margin-left 20px
            }
        }
    }
    .new_policy_chart_chartbody{
        height 80%
       /deep/ .ve-line{
            height 100% !important
        }
    }
}
.page_between{
    height 30px
    background-color #F5F6F8
}
.r_13rate{
    margin 0 6.84vw
    .r_13rate_title{
        margin 1vh 0
        font-size 40px
        font-family:PingFang SC;
        color:rgba(50,50,50,1);
    }
    .r_13rate_body{
        .R13{
            border-bottom 1px solid #F2F4FA
            margin-top 20px
            p{
                span:nth-child(1){
                    font-size 36px
                    font-family:PingFang SC;
                    font-weight:500;
                    color:rgba(65,65,65,1);
                    margin-right 20px
                }
                span:nth-child(2){
                    font-size 34px
                    font-family:PingFang SC;
                    font-weight:500;
                    color:rgba(153,153,153,1);
                }
            }
            .mount{
                display flex
                justify-content flex-end
                span{
                    font-family:LEXUS-HeiS-Bold-U;
                    color:rgba(56,56,56,1);
                }
            }
            .R13_process{
                background-color rgba(249,75,38,1)
                height 12px
                border-radius 14px
                margin-bottom 30px
            }
        }
    }
}
</style>
