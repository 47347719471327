/*
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-12-31 19:34:56
 * @LastEditors: 贾峰
 * @LastEditTime: 2019-12-31 19:37:30
 */
import axios from '@/lib/api.request'
// 新保列表
export const businessTracking = data => axios.post('/hx/reportForm/businessTracking', data)
